import config from 'constants/config'

export function getOfferTypesContent(navItems: Array<App.NavItem>) {
  const showTile = (to: string) => navItems.find(navItem => navItem.to === to)
  const { offerTypesList } = config

  const outputNavItems = offerTypesList.map(x => ({
    ...x,
    show: showTile(x.to),
  }))

  return outputNavItems
}
