import { rem } from 'polished'
import React from 'react'
import styled from 'styled-components'
import Heading from 'components/Luxkit/Typography/Heading'
import Caption from 'components/Luxkit/Typography/Caption'
import Image from 'components/Common/Image'
import TextButton from 'components/Luxkit/Button/TextButton'
import Clickable from 'components/Common/Clickable'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { KEYBOARD_MODE_CSS_VAR } from 'constants/app'

const CategoryImage = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: transform 0.3s;
  transform-origin: center center;
  transform: scale(1);
  border-radius: ${props => props.theme.borderRadius.S};
`

const GradientBackground = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  transition: backdrop-filter 0.2s;
  backdrop-filter: brightness(1);

  &.align-top {
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0) 48.26%, rgba(0, 0, 0, 0.6) 100%);
  }

  &.align-bottom {
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 48.26%);
  }
`

const CategoryCard = styled(Clickable)`
  display: flex;
  flex-direction: column;
  padding: ${rem(12)};
  min-height: ${rem(230)};

  ${mediaQueryUp.tablet} {
    padding: ${rem(16)};
    min-height: ${rem(260)};

    &:hover {
      > ${CategoryImage} {
        transform: scale(1.2);
      }
    }
  }

  border-radius: ${props => props.theme.borderRadius.S};
  position: relative;
  overflow: hidden;

  &:focus {
    outline: var(${KEYBOARD_MODE_CSS_VAR}, 2px solid  ${props => props.theme.palette.neutral.default.five});
    outline-offset: var(${KEYBOARD_MODE_CSS_VAR}, 2px);
  }

  &:hover {
      > ${GradientBackground} {
        backdrop-filter: brightness(0.8);
      }
    }
`

const TextSection = styled.div``

const ContentLayer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
  height: 100%;
  width: 100%;
  transform: translateZ(0);

  &.align-top {
    justify-content: space-between;

    > ${TextSection} {
      > * + * {
        margin-top: ${rem(4)};
      }
    }
  }

  &.align-bottom {
    justify-content: flex-end;
  }
`

interface BaseProps extends React.ComponentProps<typeof Clickable> {
  heading: string;
  to?: string;
  href?: string;
  image: App.Image;
}

interface TopProps extends BaseProps {
  align: 'top';
  subHeading: React.ReactNode;
  callToAction?: string;
}

interface BottomProps extends BaseProps {
  subHeading?: React.ReactNode;
  align: 'bottom';
  callToAction?: never;
}

type Props = TopProps | BottomProps

function CategoryTile(props: Props) {
  const { heading, subHeading, image, align, callToAction, ref, ...clickableProps } = props

  const isPlainSubheading = typeof subHeading === 'string' ||
    typeof subHeading === 'number' ||
    (Array.isArray(subHeading) && subHeading.every(value => ['string', 'number', 'boolean'].includes(typeof value)))

  return <CategoryCard {...clickableProps} ref={ref}>
    <CategoryImage>
      <Image fit="center" dpr={2} height={260} width={180} image={image} />
    </CategoryImage>
    <GradientBackground className={`align-${align}`} />
    <ContentLayer className={`align-${align}`}>
      <TextSection>
        <Heading variant="heading5" colour="neutral-eight">{heading}</Heading>
        {subHeading && (
          <>
            {isPlainSubheading && <Caption variant="large" colour="neutral-eight">{subHeading}</Caption>}
            {!isPlainSubheading && subHeading}
          </>
        )}
      </TextSection>
      {align === 'top' && callToAction && (
        <TextButton size="small" nonInteractable kind="secondary" variant="dark">
          {callToAction}
        </TextButton>
      )}
    </ContentLayer>
  </CategoryCard>
}

export default CategoryTile
